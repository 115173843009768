import store from 'amplify-store';
import contextData from '../contextData';
import LanguageDetails, { LanguageDetailsSource } from '../Types/languageDetails';

const languageDetailsKey = 'languageDetailsKey';

export enum LocalStorageKeyType {
  AuthenticationRedirectHash = 'authenticationRedirectHash',
  AuthenticationRegistrationId = 'authenticationRegistrationId',
  RequestsFilterCriteria = 'requestsFilterCriteria',
  UploadFoldersFilterCriteria = 'uploadFoldersFilterCriteria',
  UploadFoldersSorting = 'uploadFoldersSorting',
  RequestsSorting = 'requestsSorting',
  CamerasSorting = 'camerasSorting',
  RequestsPage = 'requestsPage',
  CamerasPage = 'camerasPage',
  UploadFoldersPage = 'uploadFoldersPage',
  PaginationRequestsPageSize = 'paginationRequestsPageSize',
  PaginationCamerasPageSize = 'paginationCamerasPageSize',
  PaginationUploadFoldersPageSize = 'PaginationUploadFoldersPageSize',
  PaginationUploadFolderUploadsPageSize = 'paginationUploadFolderUploadsPageSize',
  PaginationPartnerRequestUploadsPageSize = 'paginationPartnerRequestUploadsPageSize'
}

// Module to get and set values from local storage.
class LocalStorageHelper {

  getLanguageDetails = (): LanguageDetails => {
    const storedLanguageDetails = store(languageDetailsKey);
    const languageCode = storedLanguageDetails?.languageCode ?? null;
    const updatedDate = storedLanguageDetails ? new Date(storedLanguageDetails.updatedDate) : new Date(0);

    return {
      languageCode,
      updatedDate,
      source: LanguageDetailsSource.LocalStorage
    };
  };

  setLanguageDetails = (languageCode: string) => {
    const languageDetails = {
      languageCode,
      updatedDate: new Date().toJSON()
    };

    store(languageDetailsKey, languageDetails);
  };

  /**
   * Gets a value from session storage
   * @param {LocalStorageKeyType} key
   * @return {*} Returns undefined if there isn't a value for the key
   */
  getSessionValue = (key: LocalStorageKeyType) => {
    return store.sessionStorage(key);
  };

  /**
   * Persists a value to session storage.
   * @param {LocalStorageKeyType} key
   * @param {*} value Anything that can be serialized as JSON
   */
  setSessionValue = (key: LocalStorageKeyType, value: string) => {
    store.sessionStorage(key, value);
  };

  /**
   * Clears a persisted value.
   * @param {LocalStorageKeyType} key
   */
  clearSessionValue = (key: LocalStorageKeyType) => {
    store.sessionStorage(key, null);
  };

  getAuthUserSessionValue = (key: LocalStorageKeyType) => {
    const personalizedKey = this.getPersonalizedKey(key);
    return store.sessionStorage(personalizedKey);

  };

  setAuthUserSessionValue = (key: LocalStorageKeyType, value: any) => {
    const personalizedKey = this.getPersonalizedKey(key);
    store.sessionStorage(personalizedKey, value);

  };

  private getPersonalizedKey(key: LocalStorageKeyType) {
    if (!contextData.userData) {
      throw new Error('User data is not available');
    }
    const emailAddress = contextData.userData.emailAddress();
    return `${key}-${emailAddress}`;
  }
}

export default new LocalStorageHelper();
