import logger from '../Utils/logger';

/**
 * Module to catch all unhandled JavaScript errors and log them to the console.
 */
function WindowErrorHandler() {

  window.addEventListener('error', function (evt) {

    const resourceKey = 'UnhandledError';
    const debugMessage = 'Unhandled error [caught in windowErrorHandler]: \'' + evt.message +
      '\' from ' + evt.filename + ', Line:' + evt.lineno;

    console.error(debugMessage);
    console.error('Please review the error and handle this at source / not rely on the windowErrorHandler');
    logger.error(resourceKey, debugMessage, evt);
    evt.preventDefault();
  });

}

export default new WindowErrorHandler();
