import UploadModel from '@/Models/uploadModel';
import PartnerUploadModel from '@/Models/partnerUploadModel';
import PartnerZipFolderUploadModel from '@/Models/partnerZipFolderUploadModel';
import { ref, Ref } from 'vue';

export default class PartnerUploadModelAdapter {
  private static _idGeneratorIndex = 0;

  static getPartnerUploadModel(uploadModel: UploadModel): PartnerUploadModel {
    const partnerUpload: Ref<PartnerUploadModel> = ref(new PartnerUploadModel());
    partnerUpload.value.uploadId = uploadModel.uploadId;

    partnerUpload.value.requestId = uploadModel.requestId;
    partnerUpload.value.uploadToken = uploadModel.uploadToken;
    partnerUpload.value.addedTimestamp = uploadModel.addedTimestamp;
    partnerUpload.value.fileName = uploadModel.fileName;
    partnerUpload.value.size = uploadModel.fileSize;
    partnerUpload.value.fileRelativePath = uploadModel.fileRelativePath;
    partnerUpload.value.personaDisplayName = uploadModel.personaDisplayName;

    partnerUpload.value.rootFolderName = uploadModel.rootFolderName;
    partnerUpload.value.comments = uploadModel.comments;
    partnerUpload.value.nativeBrowserFile = uploadModel.nativeBrowserFile;
    partnerUpload.value.ajaxRequest = uploadModel.ajaxRequest;

    partnerUpload.value.uploadStatuses = uploadModel.uploadStatuses;
    partnerUpload.value.type = uploadModel.type;

    this.setObservableValue(partnerUpload, uploadModel, 'cameraName');
    this.setObservableValue(partnerUpload, uploadModel, 'statusName', () => {
      if (uploadModel.uploadId) {
        partnerUpload.value.uploadId = uploadModel.uploadId;
      }
    });
    this.setObservableValue(partnerUpload, uploadModel, 'progress');
    this.setObservableValue(partnerUpload, uploadModel, 'bytesUploaded');
    this.setObservableValue(partnerUpload, uploadModel, 'userIsCurrentlyUploading');

    this.setObservableValue(partnerUpload, uploadModel, 'hasChanged');
    this.setObservableValue(partnerUpload, uploadModel, 'isAuthorising');

    partnerUpload.value.uploadManagerUploadModel = uploadModel;

    // Generate temporary ID to be able to identify uploads until we receive real id. ID is required for the
    // item selection in the uploads table. When the upload is just created in upload manager it doesn't have an ID
    // until authorizing process completed.
    if (!uploadModel.uploadId) {
      this._idGeneratorIndex++;
      partnerUpload.value.uploadId = 'tmp_' + this._idGeneratorIndex.toString();
    }

    return partnerUpload.value;
  }

  private static setObservableValue(partnerUploadModel: Ref<PartnerUploadModel> | Ref<PartnerZipFolderUploadModel>,
    uploadModel: UploadModel,
    fieldName: string, callback: () => void = undefined) {
    partnerUploadModel.value[fieldName] = uploadModel[fieldName]();
    uploadModel[fieldName].subscribe(newValue => {
      partnerUploadModel.value[fieldName] = newValue;
      if (callback) {
        callback();
      }
    });
  }
}
