import { UploadParentItemType } from '@/Types/Enums/UploadParentItemType';
import PortalSettingsProvider from '@/Utils/portalSettingsProvider';
import LimitInfo from '@/Types/limitInfo';

class UploadFileLimitResolver {
  public static getUploadFileLimitByRequestType(itemType: UploadParentItemType): number {

    return itemType === UploadParentItemType.businessRequest ?
      PortalSettingsProvider.getMaxBusinessRequestUploads() : PortalSettingsProvider.getMaxPartnerRequestUploads();
  }

  public static isUploadExceedsLimitByRequestType = (totalUploads: number, uploadFileLimitByRequestType: number): boolean | undefined => {

    if (totalUploads > uploadFileLimitByRequestType) {
      return true;
    }
  };

  public static createLimitInfo(limit: number, existedUploads: number, newUploads: number, total: number): LimitInfo {
    return {
      uploadFileLimitByRequestType: limit,
      existedUploads: existedUploads,
      newUploads: newUploads,
      totalUploads: total
    };
  }
}
export default UploadFileLimitResolver;