import { FileUploadEngine, UploadOptions } from '@publicsafety/fileupload';
import { FileUploadEvent, BaseEventData, FileProgressEventData } from '@publicsafety/fileupload';

import { FileUploadEventStore } from '@publicsafety/fileupload';
import uploadManager from '@/Utils/uploadManager';
import contextData from '@/contextData';

export default class FlowWrapper {
  static init = () => {
    const uploadOptions = new UploadOptions();
    uploadOptions.fileUploadServiceBaseUrl = contextData.portalSettings.fileUploadServiceRootUrl;
    uploadOptions.simultaneousChunkUploads = contextData.portalSettings.maximumConcurrentUploads;

    FileUploadEngine.init(uploadOptions);

    // subscribe events
    FileUploadEventStore.subscribe({
      eventId: FileUploadEvent.FileStarted,
      eventHandler: (eventData: BaseEventData) => {
        uploadManager.setUploadStarted(eventData);
      }
    });

    FileUploadEventStore.subscribe({
      eventId: FileUploadEvent.FileSuccess,
      eventHandler: (eventData: BaseEventData) => {
        uploadManager.setUploadComplete(eventData);
      }
    });

    FileUploadEventStore.subscribe({
      eventId: FileUploadEvent.FileError,
      eventHandler: (eventData: BaseEventData) => {
        uploadManager.setUploadFailed(eventData);
      }
    });

    FileUploadEventStore.subscribe({
      eventId: FileUploadEvent.FileProgress,
      eventHandler: (eventData: FileProgressEventData) => {
        uploadManager.updateUploadProgress(eventData.id, eventData.percentage);
        uploadManager.setAllUploadsProgress(eventData.overallPercentage);
      }
    });
  };

  static addFileUpload = (upload: any) => {
    const fileUploads = [{
      id: upload.uploadId,
      uploadToken: upload.uploadToken,
      nativeFile: upload.nativeBrowserFile
    }];
    FileUploadEngine.addFileUploads(fileUploads);
  };

  static cancelFileUpload = (nativeFile: File) => {
    FileUploadEngine.cancelFileUpload(nativeFile);
  };
}
