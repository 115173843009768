import { AccountType } from '@/Types/Enums/accountType';
import PortalSettingsProvider from '@/Utils/portalSettingsProvider';

export default class AccountSpecificLabelsProvider {
  static getAccountLabelKey(baseLabelKey: string): string {
    const accountType = PortalSettingsProvider.getAccountType();
    if (accountType === undefined || accountType === AccountType.Business) {
      return baseLabelKey;
    }
    const labelKey = baseLabelKey + AccountType[accountType];
    return labelKey;
  }
}