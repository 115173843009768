import { AccountType } from '@/Types/Enums/accountType';
import contextData from '@/contextData';

export default class PortalSettingsProvider {
  static getAccountType(): AccountType | undefined {
    return contextData.userData?.business?.accountType;
  }

  static getSelfResponseEnabled(): boolean | undefined {
    return contextData.userData?.selfResponseEnabled;
  }

  static getMaxBusinessRequestUploads(): number {
    return contextData.portalSettings.maxBusinessRequestUploads;
  }

  static getMaxPartnerRequestUploads(): number {
    return contextData.portalSettings.maxPartnerRequestUploads;
  }

  static getMaxCctvFolderUploadSize(): number {
    return contextData.portalSettings.maximumCctvFolderUploadSize;
  }

  static getMaxUploadSize(): number {
    return contextData.portalSettings.maximumUploadSize;
  }
}