class DynamicRequestDetailsErrorMessagingAdapter {
  private clientError: KnockoutObservable<any>;
  private serverErrors: KnockoutObservable<any>;

  public init(clientErrorObservable: KnockoutObservable<any>, serverErrorsObservable: KnockoutObservable<any>) {
    this.clientError = clientErrorObservable;
    this.serverErrors = serverErrorsObservable;
  }

  public setClientError(error: any) {
    if (this.clientError) {
      this.clientError(error);
    }
  }

  public setServerErrors(errors: any) {
    if (this.serverErrors) {
      this.serverErrors(errors);
    }
  }
}

export default new DynamicRequestDetailsErrorMessagingAdapter();
