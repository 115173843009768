import Oidc from 'oidc-client';
import logger from '@/Utils/logger';
import redirectHelper from './redirectHelper';
import { LanguageDetailsSource } from '@/Types/languageDetails';

/**
 * Module to act as a wrapper around the oidc-token-manager.
 */
function TokenManager() {

  const self = this;
  let userManager = null;
  let user = null;

  self.initAsync = tokenManagerSettings => {
    userManager = new Oidc.UserManager(tokenManagerSettings);

    /**
     * @return {LanguageDetails}
     */
    return userManager.getUser()
        .then(theUser => {
          user = theUser; // Is null if the user is not authenticated

          const locale = user?.profile?.locale ?? null;

          if (locale) {
            console.debug(`The auth token contains the locale '${locale}'"`);
          } else {
            console.debug(`The auth token didn't contain a locale claim`);
          }

          return {
            languageCode: locale,
            updatedDate: user?.profile?.auth_time ?
            new Date(user.profile.auth_time * 1000) : // Convert epoch in seconds to milliseconds
            new Date(0), // Epoch 0 / milliseconds since January 1, 1970
            source: LanguageDetailsSource.AuthToken
          };

        })
        .catch(function (err) {
          console.error(err);
        });
  };

  self.validTokenExists = () => {
    return !!user && !user.expired;
  };

  self.getAccessToken = () => {

    if (!userManager) {
      throw new Error('An attempt was made to retrieve the access token before the token manager has been initialized');
    }

    return user.access_token;
  };

  self.loginRedirect = function () {

    userManager.signinRedirect()
        .then(() => {
          console.info('signinRedirect done');
        })
        .catch(err => {
          logger.logAndThrowUntranslatedError(
              'An error occurred loading the site. Please try again later.',
              'Unable to use the token manager to redirect to the authentication page. ',
              err);
        });
  };

  self.logoutRedirect = function () {
    userManager.signoutRedirect()
        .then(resp => {
          console.info('signed out', resp);
          sessionStorage.clear();
        })
        .catch(err => {
          console.error(err);
        });
  };

  /**
   * When we have a token in the URL after authentication for access to the portal is complete, pass the
   * OIDC client the token and redirect to the main portal page.
   */
  self.processLoginTokenAfterCallback = function () {
    new Oidc.UserManager().signinRedirectCallback()
        .then(user => {
          console.info('Processed the authentication token correctly. Redirecting to the main portal.', user);
          redirectHelper.postPortalAuthenticationRedirect();
        });
  };

  /**
   * When we have a token in the URL after authentication for access to the registration page is complete,
   * pass the OIDC client the token and redirect to the main registration page.
   */
  self.processRegistrationTokenAfterCallback = function () {
    new Oidc.UserManager().signinRedirectCallback()
        .then(user => {
          console.info('Processed the authentication token correctly. Redirecting to the registration screen.', user);
          redirectHelper.postRegistrationAuthenticationRedirect();
        });
  };

  self.processTokenCallbackSilent = function () {
    console.warn('Silent token renewal is not currently supported');
  };
}

export default new TokenManager();
