import resourceHelper from '@/Utils/resourceHelper';

export default class FilePathFormatter {
  static getFilePathWithoutFileName(fileRelativePath: string) {
    if (!fileRelativePath) {
      return resourceHelper.getString('NotApplicable');
    }
    const lastSeparator = fileRelativePath.lastIndexOf('/');
    if (lastSeparator !== -1) {
      return fileRelativePath.substring(0, lastSeparator);
    }

    return fileRelativePath;
  }
}