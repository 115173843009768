import { UploadType } from '@/Types/Enums/uploadType';
import { UploadContentType } from '@/Types/Enums/uploadContentType';
import { UploadParentItemType } from '@/Types/Enums/UploadParentItemType';
import resourceHelper from '@/Utils/resourceHelper';

export default class UploadTypeResolver {
  static getTypeFromNativeFile(file: File, uploadParentItemType: UploadParentItemType) {
    const isFile = file.isFile;
    if (uploadParentItemType === UploadParentItemType.businessRequest) {
      if (isFile) {
        return file.webkitRelativePath ? UploadType.folderFile : UploadType.file;
      }
      return UploadType.cctvFile;
    } else {
      const isZip = file.isZip;
      if (isFile && !file.webkitRelativePath) {
        return UploadType.file;
      }
      switch (file.uploadContentType) {
        case UploadContentType.generalContent:
          return isZip ? UploadType.zipGeneralContent : UploadType.folderFile;
        case UploadContentType.cctv:
          return isZip ? UploadType.zipCctv : UploadType.cctvFile;
        case UploadContentType.deviceBackup:
          return isZip ? UploadType.zipDeviceBackup : UploadType.deviceBackupFile;
      }
    }
  }

  static localizeUploadType(uploadType: UploadType): string {
    switch (uploadType) {
      case UploadType.deviceBackupFile:
      case UploadType.deviceBackup:
        return resourceHelper.getString('DeviceBackup');
      case UploadType.cctvFile:
      case UploadType.cctv:
        return resourceHelper.getString('CCTVFolderExport');
      case UploadType.zipGeneralContent:
        return resourceHelper.getString('ZipGeneralContent');
      case UploadType.zipDeviceBackup:
        return resourceHelper.getString('ZipDeviceBackup');
      case UploadType.zipCctv:
        return resourceHelper.getString('ZipCctv');
      default:
        return '';
    }
  }
}